import Link from 'next/link';

const HomeLink = () => (
  <Link id='home-link' href='/'>
    <img
      src='/logos/atm-nha-colored.svg'
      alt='ATM Nhà'
      loading='lazy'
      className='h-[40px] min-w-[132px]'
    />
  </Link>
);

export default HomeLink;

import Link from 'next/link';
import { PropsWithChildren } from 'react';
import { RootNavigationObject } from '..';

interface Props extends PropsWithChildren {
  navigation: RootNavigationObject;
}

const RootNavigation = ({ navigation, children }: Props) =>
  navigation.pathname ? (
    <Link
      href={navigation.pathname}
      {...(navigation.isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      className={`flex items-center ml-[16px] text-text ${
        navigation.isExtra ? 'screen-1366:flex hidden' : ''
      }`}
    >
      {children}
    </Link>
  ) : (
    <span
      className={`ml-[16px] cursor-pointer flex items-center text-text ${
        navigation.isExtra ? 'screen-1366:flex hidden' : ''
      }`}
    >
      {children}
    </span>
  );

export default RootNavigation;

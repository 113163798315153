import HomeLink from './home-link';
import Toolbar from './toolbar';

const Header2 = () => (
  <header
    id='header2'
    className='sticky top-0 left-0 right-0 z-[50] flex h-[64px] min-w-[1052px] items-center justify-between border-b border-stroke bg-paper px-[24px] py-[12px]'
  >
    <HomeLink />
    <Toolbar />
  </header>
);

export default Header2;

import Link from 'next/link';
import { PropsWithChildren } from 'react';
import { ChildNavigationObject } from '..';

interface Props extends PropsWithChildren {
  navigation: ChildNavigationObject;
}

const ChildNavigation = ({ navigation, children }: Props) =>
  navigation.pathname ? (
    <Link
      href={navigation.pathname}
      {...(navigation.isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      className='group/navigation-child relative flex items-center justify-between space-x-[12px] whitespace-nowrap bg-transparent px-[12px] py-[8px] hover:bg-secondary'
    >
      {children}
    </Link>
  ) : (
    <span className='cursor-pointer group/navigation-child relative flex items-center justify-between space-x-[12px] whitespace-nowrap bg-transparent px-[12px] py-[8px] hover:bg-secondary'>
      {children}
    </span>
  );

export default ChildNavigation;
